.keen-slider__slide {
    display: flex;
    align-items: center;
    justify-content: center;
}
.sliderTestimonials{
    height: 360px;
    max-height: 360px!important;
}
@media screen and (max-width: 400px) {
    .sliderTestimonials{
        height: 420px;
        max-height: 420px!important;
    }
}
.dot {
    border: none;
    width: 10px;
    height: 10px;
    background: #E4D7FD;
    border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
    transition: all .5s;
}
.dot:focus { outline: none;}
.dot.active { background: #7d3be2; width: 20px;border-radius: 16px;} 

.TestimonialsSlider .arrow {
    width: 36px;
    height: 36px;
    padding: 6px;
    border-radius: 100%;
    background: #ffffffb4;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    fill: #7d3be2;
    cursor: pointer;
}
.TestimonialsSlider .arrow--left { left: 3px;}
.TestimonialsSlider .arrow--right {left: auto; right: 3px;}
.TestimonialsSlider .arrow--disabled { fill: #E4D7FD;}